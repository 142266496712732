<template>
  <div class="content py-10">
      <div class="d-flex justify-center text-h4 text-md-5 font-weight-bold mb-4">GetOrders Demo Restaurant</div>
      <v-btn-toggle v-model="isGrid" class="mb-10 d-flex justify-end">
        <v-btn :value="true">Grid</v-btn>
        <v-btn :value="false">List</v-btn>
      </v-btn-toggle>

      <!-- search seaction -->
      <v-btn color="black" icon @click="searchIsActive">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <transition name="fade">
        <div v-if="searchMode">
          <v-text-field
            color="leaf"
            class="mx-5"
            v-model="searchWord"
            @keyup="search"
          >
            <template v-slot:label> Search product </template>
          </v-text-field>
          <div
            class="my-2"
            v-for="(data, index) in searchProduct"
            :key="index"
          >
            <transition name="fade">
              <v-card
                class="mt-2 mb-5"
                elevation="0"
                @click="selectProduct(data)"
                :disabled="
                  data.total_left == 0 || data.out_of_stock ? true : false
                "
              >
                <v-divider class="my-2"></v-divider>
                <v-row class="my-2">
                  <v-col
                    cols="5"
                    lg="2"
                    class="pa-1 pl-4"
                    v-if="data.media_urls.length > 0"
                  >
                    <v-img
                      max-height="120"
                      max-width="140"
                      :src="image(data.media_urls[0])"
                    ></v-img>
                  </v-col>
                  <v-col cols="5" class="pl-3 flex flex-column">
                    <h4 class="nunito font-weight-bold">{{ data.name }} in</h4>
                    <h5 class="lato red--text">Favourite</h5>
                    <div class="mt-4 mx-6" v-if="data.price_type == 0">
                <h5 class="lato font-weight-bold ml-1">
                  <div v-if="data.variants.length > 1">
                    <div v-for="(vars, index) in data.variants" :key="index">
                      <span v-if="vars.default">{{ 'RM' + vars.price }}</span>
                    </div>
                  </div>
                  <div v-else>RM {{ data.unit_price }}</div>
                </h5>
              </div>
              <div class="mt-4" v-else>
                <h5 class="lato font-weight-bold ml-1">RM {{ data.unit_price }}</h5>
              </div>
                  </v-col>
                </v-row>
              </v-card>
            </transition>
          </div>
        </div>
      </transition>
      <!-- end of search section -->
     
      <v-alert color="leaf" outlined icon="mdi-alpha-g-circle" class="mt-6">
      Welcome to the new GetOrders V2 Beta Version app
      </v-alert>

      <template  v-if="!searchWord">
         <v-sheet
        class="mx-auto mt-10"
        max-width="100%">
       
          
            <v-tabs
        color="black"
        slider-color="black"
        class="my-3"
        center-active
        hide-slider
      >
        <v-tab
          v-for="(v, i) in filteredTags"
          :key="i"
          @click="scroll(`${v.name}`)"
          class="px-2"
          ><v-card
            class="py-3 px-8 rounded-pill"
            color="leaf white--text"
            v-if="v.name == first"
          >
            {{ v.tags[0].name }}
          </v-card>
          <v-card class="py-3 px-8 rounded-pill" v-else>
            {{ v.tags[0].name }}
          </v-card>
        </v-tab>
      </v-tabs>
         
       

          </v-sheet>
      </template>
       
      
        <v-container class="mt-16" v-if="!searchWord">
          <!-- list view -->
          <div v-if="!isGrid">
            <div v-for="(group, tag) in groupedProducts" :key="tag"  >
              <h2 class="py-6 teal--text font-weight-medium">{{ tag }}</h2>
                <v-list >
                  <v-list-item v-for="data in group" :key="data.id"   :ref="data.name">
                    <!-- <div class="w-list"> -->
                    <v-list-item-content @click="navigateToProductPage(data)">
                      <v-card class="card-list px-4 py-2 d-flex justify-start">
                        <v-img
                        v-if="data.media_urls.length > 0"
                        class="img-list rounded-l-lg"
                        max-height="120"
                        max-width="140"
                        :src="image(data.media_urls[0])">
                      </v-img>
                      <div class="d-flex flex-column">
                        <div class="text-list font-weight-medium mx-6 text-h6">{{ data.name }}</div>
                        <div class="mt-4 mx-6" v-if="data.price_type == 0">
                <h5 class="lato font-weight-bold ml-1">
                  <div v-if="data.variants.length > 1">
                    <div v-for="(vars, index) in data.variants" :key="index">
                      <span v-if="vars.default">{{ 'RM' + vars.price }}</span>
                    </div>
                  </div>
                  <div v-else>RM {{ data.unit_price }}</div>
                </h5>
              </div>
              <div class="mt-4 mx-6" v-else>
                <h5 class="lato font-weight-bold ml-1">RM {{ data.unit_price }}</h5>
              </div>
                      </div>
                      </v-card>
                      
                    </v-list-item-content>
                    <!-- </div> -->
                  </v-list-item>
                </v-list>
              </div>  
          </div>


          <!-- grid view -->
          <div v-else>
            <div v-for="(group, tag) in groupedProducts" :key="tag" >
                  <h2 class="py-6 teal--text font-weight-medium">{{ tag }}</h2>
                
            <v-row class="card-product">
            <v-col v-for="data in group" :key="data.id"   :ref="data.name" cols="12" md="2">
              
              <!-- <v-card> -->
                <div class="w-content mb-10" @click="navigateToProductPage(data)">
                    <v-img
                  v-if="data.media_urls.length > 0"
                  class="rounded-l-lg d-flex justify-center"
                  :src="image(data.media_urls[0])">
                </v-img>
                <div class="mt-4">
                  <div class="font-weight-medium text-center">{{ data.name }}</div>
                  <div class="mt-4 mx-6" v-if="data.price_type == 0">
                <h5 class="lato font-weight-bold ml-1">
                  <div v-if="data.variants.length > 1">
                    <div v-for="(vars, index) in data.variants" :key="index">
                      <span v-if="vars.default">{{ 'RM' + vars.price }}</span>
                    </div>
                  </div>
                  <div v-else>RM {{ data.unit_price }}</div>
                </h5>
              </div>
              <div class="mt-4 mx-6" v-else>
                <h5 class="lato font-weight-bold ml-1">RM {{ data.unit_price }}</h5>
              </div>
                </div>
              <!-- </v-card> -->
                </div>
                
            </v-col>
          </v-row>
        </div>
          </div>
          
        </v-container>
       
      </div>
</template>

<script>
import axios from 'axios';


export default {
  data(){
    return {
      first: null,
      query : "",
      isGrid: true,
      datas: [],
      out: null,
      dialog: false,
      isLoading: false,
      searchWord: "",
      searchMode: false,
      searched: [],
      searchProduct: [],
      groupedProducts: {},
      selectedProduct: null,
  }
},
computed: {
    filteredTags() {
      const tagsMap = {};
      const filteredTags = [];
      for (const data of this.datas) {
        if (!tagsMap[data.tags[0].name]) {
          tagsMap[data.tags[0].name] = true;
          filteredTags.push(data);
          
      // console.log('data:', data);
        }

      }
      return filteredTags;
    },
  

  },
  async mounted() {
    try {
    const response = await axios.get('https://db.getorders.app/products.sl.1574.1.json');
    this.datas = response.data;
    this.groupedProducts = this.datas.reduce((acc, data) => {
      const tag = data.tags[0].name;
      if (!acc[tag]) {
        acc[tag] = [];
      }
      acc[tag].push(data);
      return acc;
    }, {});
    } catch (error) {
      console.error(error);
    // }
  }
},

  
methods: {

  scroll(refName) {
  this.first = refName;
  var element = this.$refs[refName];
  
  if (element && element.length > 0) {
    var top;
    
    if (element[0].$el) {
      top = element[0].$el.offsetTop;
    } else {
      top = element[0].offsetTop;
    }
    
    console.log("element", element);
    window.scrollTo(0, top);
  }
},

  navigateToProductPage(data) {
    this.$store.dispatch("updateSP", data);
    console.log("data:", data);
    localStorage.setItem("selectedProduct", JSON.stringify(data));

    this.$router.push({ name: 'ProductDemo', params: { id: data.id } });
    
    },

   image(i) {
     if (i.s512) {
       return i.s512;
     } else
       return (
         "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
         i.original
       );
   },
    searchIsActive() {
      this.searchMode = !this.searchMode;
      this.searchWord = "";
      this.searchProduct = [];
      localStorage.removeItem("selectedProduct");
    },

    SP() {
      if (this.searched.length > 5) {
        return this.searched.slice(Math.max(this.searched.length - 5, 1));
      } else return this.searched;
    },

    search() {
      if (this.searchWord == "") {
        this.searchProduct = [];
      } else {
        this.searchProduct = this.datas.filter((prod) => {
          var query = this.searchWord.toLowerCase();
          if (prod.name.toLowerCase().includes(query)) return true;
          return false;
        });
      }
    },

    findSearch(v) {
      this.searchWord = v;
      this.search();
    },

    selectProduct(data) {
      if (this.searchWord !== "") {
        this.searched.push(this.searchWord);
        localStorage.setItem("search", JSON.stringify(this.searched));
      }
      this.$store.dispatch("updateSP", data);
      this.$router.push({ name: 'ProductDemo', params: { id: data.id } });
    },

    beforeMount() {
    let a = localStorage.getItem("search");
    if (a) {
      this.searched = JSON.parse(a);
    }
  },
  
   
}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content{
  padding-left: 120px;
  padding-right: 120px;
}


.btn:hover{
background-color: #319177 !important;
color: white;
font-weight: 600;
}


.w-content {
/* width: 190px; */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.bg-btn{
height: 50px !important;
}

@media screen and (max-width: 950px) {
  .content{
  padding-left: 20px;
  padding-right: 20px;
}
  .w-content {
    width: 100%;
  }
  .card-product{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 5px;
  }
}


@media screen and (min-width: 400px) and (max-width:500px) {
  .content{
  padding-left: 20px;
  padding-right: 20px;
}
  .w-content {
    width: 100%;
  }
  .card-product{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 5px;
  }
}
@media screen and (min-width: 300px) and (max-width:400px) {
  .content{
  padding-left: 20px;
  padding-right: 20px;
}
  .w-content {
    width: 100%;
  }
  .card-product{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 5px;
  }
}
@media screen and (max-width: 300px){
  .content{
  padding-left: 20px;
  padding-right: 20px;
}
  .w-content {
    width: 100%;
  }
  .card-product{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 5px;
  }
}

/* @media screen and (max-width: 375px){
  .img-list{
    max-width: 120px !important;
    max-height: 120px !important;
  }
  .text-list{
    font-size: 18px !important;
  }
} */

@media screen and (max-width: 400px){
  .img-list{
    max-width: 120px !important;
    max-height: 120px !important;
  }
  .text-list{
    font-size: 15px !important;
  }
}
@media screen and (max-width: 350px){
  .img-list{
    max-width: 100px !important;
    max-height: 100px !important;
  }
  .text-list{
    font-size: 14px !important;
  }
}
@media screen and (max-width: 300px){
  .img-list{
    max-width: 80px !important;
    max-height: 80px !important;
  }
  .text-list{
    font-size: 10px !important;
  }
}


</style>
